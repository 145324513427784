<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class=" dingbu">
          <div class=" header zuo"><h1><a href="/" rel="nofollow"><img style="height: 100px"
                                                                       src="@/assets/logo.png" alt="自动秒收录"></a></h1>
          </div>
          <div class=" search you">
            <form name="f" method="get" action="">
              <div class=" input-group searchbox">
                <input type="text" name="name" size="11" id="edtSearch" value=""
                       placeholder="请输入域名、标题、关键词、进行搜索。" class=" form-control">
                <a class=" clearbtn" style="top: 13px; left: 1022px;"></a>
                <div class=" input-group-append">
                  <button type="submit" class=" btn btn-outline" id="searchbtn"><i
                      class=" fa fa-search"></i></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class=" col">
        <div class=" left zuo zuixindh">
          <div class=" card-header">
            <div class=" card-title">
              <h6 class=" card-title more" style="color: #ff1600">本站已收录{{ count }}个网站</h6>
              <h6 class=" card-title bianse"><i class=" fa fa-link fa-fw"></i>最新点入：做上本站友情链接,在您站上点击一次,即可自动收录并自动排在本站第一位!
              </h6>
            </div>
          </div>
          <ul>
            <li v-for="(value) in list" :key="value">
              <p><i class=" fa fa-link fa-fw"></i><a :href="value.url" :title=" value.title"
                                                     target="_blank">{{ value.title }}</a></p>
            </li>
          </ul>
        </div>
        <div class="right you zuixinwz">
          <div class="card-header">
            <h5 class=" card-title"><i class=" fa fa-thumbs-up"></i>最新加入</h5>
          </div>
          <ul>
            <li v-for="(value) in recommend" :key="value">
              <p><i class=" fa fa-link fa-fw"></i><a :href="value.url" :title=" value.title"
                                                     target="_blank">{{ value.title }}</a></p>
            </li>
          </ul>
        </div>
      </div>
      <div class=" col">
        <div class=" card zuixindh">
          <div class=" card-header">
            <h5 class=" card-title"><i class=" fa fa-home"></i>随机推荐</h5>
          </div>
          <ul>
            <li v-for="(value) in random" :key="value">
              <p><i class=" fa fa-link fa-fw"></i><a :href="value.url" :title=" value.title"
                                                     target="_blank">{{ value.title }}</a></p>
            </li>
          </ul>
        </div>
      </div>
      <div class=" col">
        <div class=" card">
          <div class=" card-header">
            <h5 class=" card-title"><i class=" fa fa-link fa-fw"></i>友情链接</h5>
          </div>
          <div class=" row no-gutters dhsl">
            <ul>
            </ul>
          </div>
        </div>
      </div>
      <div class="col footer">
        <div class="footer-wrap">
          <p>
            Copyright @ 2021
            <span>此内容系本站根据来路自动抓取的结果，不代表本站赞成被显示网站的内容或立场。</span>
          </p>
          <p>
            本页阅读量<span id="busuanzi_value_page_pv"></span>次 |
            本站总访问量<span id="busuanzi_value_site_pv"></span>次 |
            本站总访客数<span id="busuanzi_value_site_uv"></span>人 |
            <a href="/sitemap.xml" target="_blank">网站地图</a> |
          </p>
        </div>
      </div>
    </div>
  </div>

  <el-backtop/>
</template>
<script>
import axios from 'axios'

export default {

  computed: {
    axios
  },
  data() {
    return {
      list: [],
      random: [],
      recommend: [],
      count: 0,
    }
  },
  methods: {},
  created() {
    var that = this
    axios.post("./ajax.php?act=getData").then(function (res) {
      that.list = res.data.data.list
      that.recommend = res.data.data.recommend
      that.random = res.data.data.random
      that.count = res.data.data.count
    })
  }
}
</script>
<style>

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px
  }
}

body {
  margin: 0;
}

@media (min-width: 992px) {
  .container {
    max-width: 960px
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px
  }
}

</style>
